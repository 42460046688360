.rce-mlist {
	position: absolute !important;
	width: 100% !important;
	padding: 5rem 1rem 2rem !important;
}

.no-scroll::-webkit-scrollbar {
	display: none;
}

.no-scroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.spacing-x-4 {
	margin: 0 0.5rem;
}

.str-video {
	height: 100%;
}